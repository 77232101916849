import { Component, DestroyRef, inject } from '@angular/core';
import { LayoutService } from '../../../../layout/layout.service';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../../../../core/services/profile.service';
import { GlobicaService } from '../../../../core/services/globica.service';
import { ProfileDetail } from '../../../../core/interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-reports-wizard',
  templateUrl: './reports-wizard.component.html',
  styleUrls: ['./reports-wizard.component.scss']
})
export class ReportsWizardComponent {
  private destroyRef = inject(DestroyRef);
  public isShowWizard = false;

  profile: ProfileDetail;

  constructor(
    private layoutService: LayoutService,
    private profileService: ProfileService,
    private globica: GlobicaService
  ) {}

  init(): void {
    this.profileService.profile$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(profile => !!profile)
      )
      .subscribe({
        next: (profile: ProfileDetail) => {
          this.profile = profile;
        }
      });

    const wizards = this.profile.wizards;
    if (wizards && wizards.indexOf('reports') === -1) {
      this.isShowWizard = true;
      document.body.classList.add('hidden');
    }
  }

  /**
   * Close Wizard
   * Mark wizard as watched
   */
  close(iSee: boolean): void {
    /** Track globica event */
    this.globica.trackEventGoals(iSee ? 'buttonISee_click' : 'buttonClose_click');

    this.isShowWizard = false;
    document.body.classList.remove('hidden');

    this.layoutService
      .setWizardShowed({ name: 'reports' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.status === 'OK') {
          const profile = {
            ...this.profile,
            wizards: ['reports', ...this.profile.wizards]
          };

          this.profileService.setProfile(profile);
        }
      });
  }
}
