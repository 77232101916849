<div
  class="reports-wizard"
  *ngIf="isShowWizard"
>
  <div class="reports-wizard__mask"></div>

  <div class="reports-wizard__container">
    <span
      class="material-icons reports-wizard__close-btn"
      (click)="close(false)"
    >
      close
    </span>

    <div class="reports-wizard__top-line"></div>
    <div class="material-icons reports-wizard__arrow">keyboard_arrow_left</div>

    <div class="reports-wizard__text">{{ 'wizard_clickHereForFiltering' | transloco }}</div>

    <button
      class="reports-wizard__action"
      adxadStrokedButton
      (click)="close(true)"
    >
      {{ 'action_iSee' | transloco }}
    </button>
  </div>
</div>
