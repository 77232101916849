import { Routes } from '@angular/router';
import { ReportGridComponent } from './components/report-grid/report-grid.component';

export const routes: Routes = [
  {
    path: '',
    component: ReportGridComponent,
    title: 'Reports',
    data: {
      pageType: 'REPORTS'
    }
  }
];
