<div
  class="adxad-groups"
  [ngClass]="{'disabled': disabled}"
>
  <div class="adxad-groups__select">
    <div
      class="adxad-groups__label"
      [ngClass]="{'disabled': disabled}"
      (click)="togglePopup()"
    >
      {{ 'action_breakDownBy' | transloco }}
    </div>

    <div
      class="adxad-groups-popup"
      *ngIf="showPopup"
      @fadeOnOut
    >
      <section class="adxad-groups-popup__section adxad-groups-popup__section_selected">
        <header class="adxad-groups-popup__section-header">
          <div class="adxad-groups-popup__section-title">{{ 'label_selected' | transloco }}</div>

          <button
            [disabled]="isDisabledClearBtn"
            class="adxad-groups-popup__section-header-btn"
            (click)="clearAll()"
          >
            {{ 'action_clearAll' | transloco }}
          </button>
        </header>

        <ul class="adxad-groups-popup__list">
          <li
            class="adxad-groups-popup__list-item checked"
            *ngFor="let param of selectedParams"
            (click)="toggleParam(param)"
            [ngClass]="{'disabled': isDisabledClearBtn}"
          >
            <div class="adxad-groups-popup__list-item-checkbox">
              <span class="material-icons"> check </span>
            </div>
            {{ param.value | transloco }}
          </li>
        </ul>
      </section>

      <section class="adxad-groups-popup__section">
        <header class="adxad-groups-popup__section-header">
          <div class="adxad-groups-popup__section-title">{{ 'label_available' | transloco }}</div>
          <button
            [disabled]="isDisabledSelectBtn"
            class="adxad-groups-popup__section-header-btn"
            (click)="selectAll()"
          >
            {{ 'action_selectAll' | transloco }}
          </button>
        </header>

        <ul class="adxad-groups-popup__list">
          <li
            class="adxad-groups-popup__list-item"
            *ngFor="let param of availableParams"
            (click)="toggleParam(param)"
          >
            <div class="adxad-groups-popup__list-item-checkbox">
              <span class="material-icons"> check </span>
            </div>

            {{ param.value | transloco }}
          </li>
        </ul>
      </section>
    </div>
  </div>

  <div class="adxad-groups__params">
    <div
      class="adxad-groups__params-wrapper"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (wheel)="scrollParams($event)"
      (cdkDropListDropped)="dndParams($event)"
    >
      <div
        class="adxad-groups__param"
        cdkDrag
        [ngClass]="{'disabled': disabled || this.selectedParams.length === 1}"
        *ngFor="let param of resultParams"
      >
        {{ param.value | transloco }}
        <span
          class="material-icons"
          (click)="toggleParam(param)"
        >
          cancel
        </span>
      </div>
    </div>
  </div>
</div>
