import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DspService, Request } from '../../core/services/dsp.service';
import { Observable } from 'rxjs';
import { Config, EntityResponse, GroupsConfig, ReportsData, ToggleSpotsBlock } from '../../core/interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  /**
   * Get statistic grid
   * @param request
   */
  getReports(request: Request): Observable<ReportsData> {
    return this.http.get<ReportsData>(`${this.config.dsp_endpoint}/report`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Get groups config
   */
  getGroups(): Observable<GroupsConfig> {
    return this.http.get<GroupsConfig>(`${this.config.dsp_endpoint}/report/groups`);
  }

  /**
   * Activate or block spots
   * @param data
   */
  toggleSpotBlock(data: ToggleSpotsBlock): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.dsp_endpoint}/targeting/campaign/spots`, data, { headers });
  }
}
