<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <header class="header">
    <section class="adxad-toolbar">
      <div class="adxad-toolbar__diff">
        <div class="action">
          <adxad-calendar
            (changeDates)="filter.changeCalendarDates($event)"
            [from]="filter.calendar?.value?.calendar.from"
            [to]="filter.calendar?.value?.calendar.to"
          ></adxad-calendar>

          <adxad-slide-toggle
            (toggle)="filter.getDiff()"
            [checked]="filter.showDiff"
            [sizeMini]="true"
            *ngIf="roles.isManagers()"
          >
            Show difference
          </adxad-slide-toggle>
        </div>

        <div
          class="action"
          *ngIf="filter.showDiff"
        >
          <adxad-calendar-diff
            [diffFrom]="filter.diff?.value?.diff.diffFrom"
            [diffTo]="filter.diff?.value?.diff.diffTo"
            [prevFrom]="filter.calendar?.value?.calendar.from"
            [prevTo]="filter.calendar?.value?.calendar.to"
            (changeDates)="filter.changeDiffCalendarDates($event)"
          ></adxad-calendar-diff>
        </div>
      </div>

      <div class="adxad-toolbar__actions">
        <div
          class="adxad-toolbar__download"
          *ngIf="!filter.showDiff"
        >
          <adxad-help-icon
            adxadTooltip="{{ 'tooltips_downloadCsv' | transloco }}"
            placement="left"
          ></adxad-help-icon>

          <button
            (click)="globica.trackEventGoals('buttonDownloadCsv_click')"
            (downloadLoading)="isCsvLoading = $event.loading"
            [disabled]="!downloadCsvUrl || isLoading || isNoResults || isCsvLoading"
            [downloadUrl]="downloadCsvUrl"
            adxadButton
            appDownloadFile
            class="btn-with-loader"
            color="gray"
          >
            <adxad-loader
              *ngIf="isCsvLoading"
              [diameter]="20"
              class="btn-with-loader__loader"
            ></adxad-loader>
            <span class="btn-with-loader__content"> {{ 'action_downloadCsv' | transloco | uppercase }} </span>
          </button>
        </div>

        <adxad-filter-actions
          (applyFilter)="applyFilter()"
          (clearFilters)="clearFilters()"
          (openFilter)="openFilter()"
          [count]="filter.count"
          [notApplied]="filter.isNotApplied"
        />
      </div>
    </section>
    <app-groups
      (changeGroupsOrder)="changeColumnsOrder($event)"
      class="w100"
    />
  </header>

  <adxad-info-block
    *ngIf="!isValidDates"
    [type]="'warning'"
  >
    {{ 'dateHourAlert' | transloco }}
  </adxad-info-block>

  <adxad-dynamic-grid
    (action)="toggleFilter($event)"
    (reload)="loadGrid()"
    [loading]="isLoading"
    [ngClass]="{'hide': isNoResults}"
  />
  <adxad-grid-not-found
    (clearFilters)="clearFilters()"
    *ngIf="isNoResults"
  />
  <adxad-paginator (changePage)="loadGrid()" />
</div>
